<template>
	<el-dialog top="1vh" title="绑定详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit" style="margin-top:0px;">车主数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车主姓名" style="width:388px">
					<el-input class="el_inner_width" v-model="form.truck_owner_name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车主电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.truck_owner_tel" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">绑定数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="绑定姓名" style="width:388px">
					<el-input class="el_inner_width" v-model="form.driver_name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="绑定电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.driver_tel" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">绑定证件</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!dl_img_src" class="btn">未上传</div>
						<img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)">
					</div>
					<div class="bottom_text">驾驶证</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!cy_img_src" class="btn">未上传</div>
						<img  v-else :src="cy_img_src" class="img" @click="show_img(cy_img_src)">
					</div>
					<div class="bottom_text">从业资格证</div>
				</div>
			</div>


			<div v-if="form.truck_owner_tel!=form.driver_tel">

				<div class="big_tit">绑定来源</div>
				<div class="tab1_inner">
					<el-form-item class="el_form_item" label="来源说明" style="width:49%">
						<el-input class="el_inner_width" v-model="form.driver_source" disabled></el-input>
					</el-form-item>
					<div class="el_form_item" label="" style="width:48%">
						<div class="img_btn">
							<div v-if="!driver_source_proof_src" class="btn">未上传</div>
							<img  v-else :src="driver_source_proof_src" class="img" @click="show_img(driver_source_proof_src)">
						</div>
						<div class="bottom_text">车辆来源凭证</div>
					</div>
				</div>


				<div class="big_tit">审核相关</div>
				<div class="tab1_inner">
					<el-form-item class="el_form_item" label="驳回原因" style="width:100%">
						<el-input class="el_inner_width" v-model="form.faild_reason" clearable></el-input>
					</el-form-item>
				</div>
			</div>

		</el-form>

		<div class="bottom_btns" v-if="form.truck_owner_tel!=form.driver_tel">
			<el-button type="success" @click="pass()">通过</el-button>
			<el-button type="danger" @click="unpass()">驳回</el-button>
		</div>

		<!-- <el-dialog
			top="2vh"
			width="90%"
			title="查看大图"
			:modal="false"
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog> -->
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			bind_info:Object,//绑定详情
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//绑定id
				bind_id:'',

				//表单
				form:{
					status:'',//状态(1:审核中,2:审核通过,3:审核未通过)
					truck_owner_name:'',//车主姓名
					truck_owner_tel:'',//车主电话
					driver_name:'',//司机姓名
					driver_tel:'',//司机电话
					driver_source:'',//司机来源
					faild_reason:'',//审核失败原因
				},

				//驾驶证
				dl_img_src:'',

				//从业资格证
				cy_img_src:'',

				//绑定来源证明
				driver_source_proof_src:'',

				//大图
				img:{
					is_show:false,
					src:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.page_init();
					this.is_show_in_page=true;
				}
			},
		},
		methods: {

			//显示大图
			// show_img(src){
			// 	this.img.src=src
			// 	this.img.is_show=true
			// },

			//不通过
			unpass(){

				//失败原因
				if(!this.form.faild_reason.trim()){
					this.$my.other.msg({
						type:'warning',
						str:'缺少失败原因'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'驳回审核",
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_bind_edit_by_admin',
								bind_id:this.bind_id,
								status:3,
								faild_reason:this.form.faild_reason.trim()
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},

			//通过
			pass(){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'通过审核",
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_bind_edit_by_admin',
								bind_id:this.bind_id,
								status:2,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},

			//页面初始化
			page_init(){

				let bind_info=this.bind_info

				//大图部分
				this.img.is_show=false
				this.img.src=''

				//绑定表id
				this.bind_id=bind_info.id

				//表单部分
				this.form.status=bind_info.status
				this.form.truck_owner_name=bind_info.truck_owner_info.name
				this.form.truck_owner_tel=bind_info.truck_owner_info.tel
				this.form.driver_name=bind_info.driver_info.name
				this.form.driver_tel=bind_info.driver_info.tel
				this.form.driver_source=bind_info.driver_source
				this.form.faild_reason=bind_info.faild_reason

				//驾驶证
				this.dl_img_src=this.$my.qiniu.make_src('driverdl',bind_info.cert_info.dl_img)

				//从业资格证
				this.cy_img_src=this.$my.qiniu.make_src('drivercy',bind_info.cert_info.cy_img)

				//绑定来源证明
				this.driver_source_proof_src=this.$my.qiniu.make_src('driversourceproof',bind_info.driver_source_proof)
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}

</style>