 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核驳回" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="创建时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="车主信息">
					<template slot-scope="scope">
						{{scope.row.truck_owner_info.name}} / {{scope.row.truck_owner_info.tel}}
					</template>
				</el-table-column>
				<el-table-column label="司机信息">
					<template slot-scope="scope">
						{{scope.row.driver_info.name}} / {{scope.row.driver_info.tel}}
					</template>
				</el-table-column>
				<el-table-column label="司机驾驶证">
					<template slot-scope="scope">
						{{scope.row.cert_info.has_upld_dl_img}}
						<el-button @click="show_big_img('driverdl',scope.row.cert_info.dl_img)" size="mini" type="text">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column label="从业资格证">
					<template slot-scope="scope">
						{{scope.row.cert_info.has_upld_cy_img}}
						<el-button @click="show_big_img('drivercy',scope.row.cert_info.cy_img)" size="mini" type="text">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column label="司机证件审核状态">
					<template slot-scope="scope">
						{{scope.row.cert_info.status_text}}
					</template>
				</el-table-column>
				<el-table-column label="司机来源">
					<template slot-scope="scope">
						<div v-if="scope.row.driver_source">
							{{scope.row.driver_source}}
							<el-button @click="show_big_img('driversourceproof',scope.row.driver_source_proof)" size="mini" type="text">查看凭证</el-button>
						</div>
						<div v-else>未声明</div>
					</template>
				</el-table-column>
				<el-table-column label="绑定审核状态" prop="status_text"></el-table-column>

				
				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="48">
					<template slot-scope="scope">
						<el-button class="btn_left" v-if="scope.row.status==2" @click="open_check_view(scope.row)" size="mini" type="text">详情</el-button>
						<el-button class="btn_left" v-else @click="open_check_view(scope.row)" size="mini" type="text">审核</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 查看大图 -->
		<el-dialog
			top="2vh"
			width="90%"
			title="查看大图"
			:modal="false"
			:visible.sync="big_img.is_show"
		>
			<img :src="big_img.src" style="width:100%">
		</el-dialog>

		<!-- 绑定详情 -->
		<bindInfo
			:is_show="bind_info.is_show" 
			:bind_info="bind_info.data" 
			@close="check_view_close"
		></bindInfo>

	</div>
</template>

<script>
	import bindInfo from './bind_info.vue'
	export default {
		components:{
			bindInfo
		},
		data() {
			return {

				//搜索条件
				form: {	
					truck_owner_tel:'',//车主手机
					driver_tel:'',//车主手机
					status:'',//状态(1:审核中,2:审核通过,3:审核未通过)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//大图
				big_img:{
					is_show:false,
					src:''
				},

				//审核弹出层
				bind_info:{
					data:{},
					is_show:0,
				},
			}
		},
		created() {
			
			//获取页面数据
			this.get_page_data()
		},
		methods: {

			//打开货车详情
			open_check_view(item){

				//置入数据
				this.bind_info.data=item

				//打开弹出层
				this.bind_info.is_show++;
			},
			check_view_close(){//上报界面关闭回调

				//复位
				this.bind_info.is_show=0;

				//清空数据
				this.bind_info.data={}
				
				//刷新页面
				this.get_page_data()
			},

			//查看大图
			show_big_img(bucket,key){
				if(!key)return
				this.big_img.src=this.$my.qiniu.make_src(bucket,key)
				this.big_img.is_show=true;
			},
			
			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_list_by_admin',
						is_get_cert_info:1,
						is_get_driver_info:1,
						is_get_truck_owner_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//绑定审核状态
							switch(item.status){
								case '1':item.status_text='审核中';break;
								case '2':item.status_text='审核通过';break;
								case '3':item.status_text='审核未通过';break;
							}

							//司机状态
							switch(item.cert_info.status){
								case '1':item.cert_info.status_text='审核中';break;
								case '2':item.cert_info.status_text='审核通过';break;
								case '3':item.cert_info.status_text='审核未通过';break;
							}

							//驾驶证图片
							if(item.cert_info.dl_img){
								item.cert_info.has_upld_dl_img="已上传"
							}else item.cert_info.has_upld_dl_img="未上传"

							//从业资格证照片
							if(item.cert_info.cy_img){
								item.cert_info.has_upld_cy_img="已上传"
							}else item.cert_info.has_upld_cy_img="未上传"
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	.in_check_img_item{
		width: 500px;
	}

	.in_tab_img_item{
		width: 100px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 100px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>